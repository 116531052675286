import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { sellerBillingApi } from 'src/shared/api';
import { TSellerWithdrawal, TSellerWithdrawalSum } from 'src/shared/api/queries/sellerBillingApi';
import { AxiosError } from 'axios';
import { notification } from 'antd';

import { TWithdrawalFx } from '../PaymentsWithdrawalPage.types';
import {Hooks} from "../../../../shared/lib";

type TReturnValues = {
  readonly loading: boolean;
  readonly withdrawals: TSellerWithdrawal[];
  readonly sum: TSellerWithdrawalSum[];
  readonly onWithdrawal: (values: TWithdrawalFx) => Promise<void>;
  readonly onExport: () => Promise<void>;
};

const PaymentsWithdrawalContext = createContext<Partial<TReturnValues>>({});

export const PaymentsWithdrawalProvider = ({ children }: { children: ReactNode }) => {
  const [withdrawals, setWithdrawals] = useState<TSellerWithdrawal[]>([]);
  const [sum, setSum] = useState<TSellerWithdrawalSum[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchParams] = Hooks.useSearchParamsObject();
  // eslint-disable-next-line camelcase
  const { pavilion_id, statuses, supplier_id, date_from, date_to } = searchParams;
  const onExport = async () => {
    try {
      // const response = await sellerBillingApi.exportSellerBalances();
      const params = getParams();
      const response = await sellerBillingApi.exportWithdrawalRequests(params.length !== 0 ? `?${params.join('&')}` : '');
      if (window && response?.url) {
        window.location.replace(response?.url);
      }
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      notification.error({ message: error.response?.data.message || 'Ошибка экспорта балансов' });
    }
  };

  const getParams = () => {
    const params = [];
    // eslint-disable-next-line camelcase
    if (pavilion_id) {
      // eslint-disable-next-line camelcase
      params.push(`pavilion_id=${pavilion_id}`);
    }
    // eslint-disable-next-line camelcase
    if (supplier_id) {
      // eslint-disable-next-line camelcase
      params.push(`supplier_id=${supplier_id}`);
    }
    if (statuses) {
      statuses.split(',').forEach((el) => {
        params.push(`statuses[]=${el}`);
      })
    }
    // eslint-disable-next-line camelcase
    if (date_from || date_to) {
      // eslint-disable-next-line camelcase
      if (date_from) {
        // eslint-disable-next-line camelcase
        params.push(`dates[from]=${date_from}`);
      }
      // eslint-disable-next-line camelcase
      if (date_to) {
        // eslint-disable-next-line camelcase
        params.push(`dates[to]=${date_to}`);
      }
    }
    return params;
  }

  const fetchBalances = async () => {
    try {
      setLoading(true);
      const params = getParams();
      const response = await sellerBillingApi.getSellerWithdrawals(params.length !== 0 ? `?${params.join('&')}` : '');
      setWithdrawals(response.withdrawals)
      setSum(response.sum)
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      notification.error({ message: error.response?.data.message || 'Ошибка загрузки балансов' });
    } finally {
      setLoading(false);
    }
  };

  const onWithdrawal = async ({ id, data, callback }: TWithdrawalFx) => {
    try {
      await sellerBillingApi.updateSellerBalance(id, data);
      fetchBalances();
      callback();
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      notification.error({ message: error.response?.data.message || 'Ошибка списания средств' });
    }
  };

  useEffect(() => {
    fetchBalances();
    // eslint-disable-next-line camelcase
  }, [pavilion_id, statuses, supplier_id, date_from, date_to]);

  const values = useMemo(
    () => ({
      loading,
      withdrawals,
      onWithdrawal,
      onExport,
      sum,
    }),
    [loading, withdrawals],
  );

  return <PaymentsWithdrawalContext.Provider value={values}>{children}</PaymentsWithdrawalContext.Provider>;
};

export const usePaymentsWithdrawal = (): Partial<TReturnValues> => ({ ...useContext(PaymentsWithdrawalContext) });
