import {AutoComplete, Button} from "antd";
import {useState} from "react";

import {Pavilion} from "../../../../../shared/types";
import styles from "../../PaymentsWithdrawalPage.module.less";
import {Hooks} from "../../../../../shared/lib";

const MobilePavilionSearch = ({
    options, searchHandler, selectHandler, resetHandler,
                              }: {
    options: Pavilion[],
    searchHandler: (value: string) => void,
    selectHandler: (value: number) => void,
    resetHandler: () => void,
}) => {
    const [searchParams] = Hooks.useSearchParamsObject();
    // eslint-disable-next-line camelcase
    const { pavilion_id } = searchParams;
    // eslint-disable-next-line camelcase
    const [value, setValue] = useState<string>(pavilion_id ? (options.find((el) => el.id === +pavilion_id)?.name || '') : '');
    const handleSearch = (value: string) => {
        setValue(value);
        searchHandler(value);
    };

    const handleSelect = (value: string, options: { label: string }) => {
        setValue(options?.label || '');
        selectHandler(Number(value));
    };

    const filteredOptions = options?.map(({ id, name }) => ({ label: name, value: name, key: id, disabled: false })) || [];

    return (
        <div className={styles.mobilePavilionSearch}>
            <AutoComplete
                value={value}
                onSelect={handleSelect}
                onChange={handleSearch}
                placeholder='Поиск по павильону'
            >
                {filteredOptions?.map(({ value, key, label, disabled }) => (
                    <AutoComplete.Option key={key} label={label} disabled={disabled}>
                        {value}
                    </AutoComplete.Option>
                ))}
            </AutoComplete>
            {
                value &&
                <Button onClick={() => {
                    setValue('')
                    resetHandler()
                }} size="middle" style={{display: 'block'}}>
                    Сбросить
                </Button>
            }
        </div>
    )
}

export { MobilePavilionSearch };