import { notification } from 'antd';
import { AxiosError } from 'axios';

export const getNotification = ({
  error,
  altMessage,
  type = 'error',
}: {
  error?: any;
  altMessage?: string;
  type?: 'error' | 'success';
}) => {
  const e = error as AxiosError<{ message: string }>;
  const obj = {
    message: e?.response?.data?.message || altMessage || '',
  };
  switch (type) {
    case 'success':
      notification.success(obj);
      break;
    default:
      notification.error(obj);
  }
};

export const downloadFileUtil = ({ file, filename, type }: { file: Blob; filename: string; type?: string }) => {
  const binaryData = [];
  binaryData.push(file);
  const url = window.URL.createObjectURL(new Blob(binaryData, { type: type || 'application/zip' }));

  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.download = filename;
  link.click();
};
