import {Button, Empty, Switch, Table, Tag} from 'antd';
import React, {useEffect, useState} from 'react';
import {TableSettings} from 'src/entities/tableSettings';
import {ColumnsType} from 'antd/es/table';
import {Statuses, TSellerWithdrawal} from 'src/shared/api/queries/sellerBillingApi';
import {Link} from "react-router-dom";
import {useStore} from "effector-react";

import {CopyText, getColumns} from '../../PaymentsWithdrawalPage.utils';
import styles from '../../PaymentsWithdrawalPage.module.less';
import {usePaymentsWithdrawal} from '../../hooks/usePaymentsWithdrawal';
import {WithdrawalModal} from '../WithdrawalModal';
import {defaultInitState, statusColors} from "../../../../../shared/config";
import {getFormattedPhone, Hooks, Utils} from "../../../../../shared/lib";
import {$pavilions, initPagePavilions} from "./model";
import {initState} from "../../../suppliers/PaymentsSuppliersPage.const";
import {MobilePavilionSearch} from "./MobilePavilionSearch";

export const statusOptions = [
    {
        label: 'Ожидает',
        value: Statuses.PENDING,
    },
    {
        label: 'Подтвержден',
        value: Statuses.APPROVED,
    },
    {
        label: 'Отклонен',
        value: Statuses.REJECTED,
    }
];

export const Content: React.FC = () => {
  const { withdrawals, loading, onExport, sum } = usePaymentsWithdrawal();
  const [withdrawal, setWithdrawal] = useState<TSellerWithdrawal>();
  const [newStatus, setNewStatus] = useState<Statuses>(Statuses.APPROVED);
  const activeSum = sum?.find((el) => el.status === Statuses.PENDING)?.sum;
  const approvedSum = sum?.find((el) => el.status === Statuses.APPROVED)?.sum;
  const [searchParams, setSearchParams] = Hooks.useSearchParamsObject();
    const [isActiveFilter, setIsActiveFilter] = useState(false);
    const pavilions = useStore($pavilions);
    const { suppliers, setParams: setSupplierSearchParams } = Hooks.useSearchSupplier();


    useEffect(() => {
        initPagePavilions(defaultInitState);
    }, []);
    const onWithdrawal = (value: TSellerWithdrawal, status: Statuses) => {
        setWithdrawal(value);
        setNewStatus(status)
    }
  const { columns, initColumnSettings } = getColumns({
    onWithdrawal,
  });
  const [visibleColumns, setVisibleColumns] = useState(initColumnSettings);

    const searchPavilionHandler = (value: string) => {
        const reqData = value ? { ...defaultInitState, query: value } : { ...defaultInitState };
        initPagePavilions(reqData);
    };

    const resetPavilionHandler = () => {
        const { pavilion_id: pavilionId, ...restParams } = searchParams;
        setSearchParams({ ...restParams, page: 1 });
    };

    const handleSelectPavilion = (value: number) => {
        setSearchParams({ ...searchParams, pavilion_id: value, page: 1 });
    };

    const getPavilionsSearch = Hooks.useColumnAutocomplete<TSellerWithdrawal>(
        searchPavilionHandler,
        handleSelectPavilion,
        resetPavilionHandler,
        pavilions?.items || [],
        isActiveFilter,
        setIsActiveFilter,
    );

    const handleSelectStatus = (value: string[]) =>
        setSearchParams({ ...searchParams, statuses: value || null, page: 1 });

    const getStatusSearch = Hooks.useColumnMultiSelect<TSellerWithdrawal>(
        handleSelectStatus,
        statusOptions,
        'Выбрать статус',
        isActiveFilter,
        setIsActiveFilter,
        searchParams.status,
    );

    const getResultDateTo = (date: Date) => {
        date.setHours(23,59,59,999);
        return date;
    }

    const searchCreatedDateHandler = (data: { date_after: string; date_to: string }) => {
        setSearchParams?.({
            ...searchParams,
            // @ts-ignore
            date_from: data.date_after ? new Date(data.date_after).toISOString() : undefined,
            // @ts-ignore
            date_to: data.date_to ? getResultDateTo(new Date(data.date_to)).toISOString() : undefined,
        });
    }

    const getDateSearch = Hooks.useColumnSearchDate<TSellerWithdrawal>(
        searchCreatedDateHandler,
        isActiveFilter,
        setIsActiveFilter,
        'created_at',
    );

    const searchSupplierHandler = (value: string) => {
        if (value) {
            setSupplierSearchParams({ ...initState, name: value });
        }
    };

    const handleSelectSupplier = (value: number) => {
        setSearchParams?.({...searchParams, supplier_id: value});
    };

    const resetSupplierHandler = () => {
        // eslint-disable-next-line camelcase
        const { supplier_id, ...other } = searchParams;
        setSearchParams?.({...other, page: 1});
    };

    const getSupplierSearch = Hooks.useColumnAutocomplete<TSellerWithdrawal>(
        searchSupplierHandler,
        handleSelectSupplier,
        resetSupplierHandler,
        suppliers?.items || [],
        isActiveFilter,
        setIsActiveFilter,
    );

  const filteredColumns: ColumnsType<TSellerWithdrawal> = columns!
    .filter(i => (i.key ? visibleColumns[i.key] : true))
    .map(column => {
      switch (column.key) {
          case 'pavilions':
              return { ...column, ...getPavilionsSearch(column.key, column.title) };
          case 'status':
              return { ...column, ...getStatusSearch(column.key, column.title) };
          case 'supplier':
              return { ...column, ...getSupplierSearch(column.key, column.title) };
          case 'created_at':
              return { ...column, ...getDateSearch(column.key, column.title) };
        default:
          return column;
      }
    });

  return (
    <div>
      <div className={styles.searchSettings}>
          <div className={styles.balances}>
              <span className={styles.nowrap} style={{display: 'block', marginRight: 6}}>Активно: {new Intl.NumberFormat().format(activeSum ? activeSum / 100 : 0)} ₽,</span>
              <span className={styles.nowrap} style={{display: 'block'}}> Погашено: {new Intl.NumberFormat().format(approvedSum ? approvedSum / 100 : 0)} ₽</span>
          </div>
        <div className={styles.options}>
          <Button onClick={onExport} disabled={loading}>
            Экспорт в Excel
          </Button>
          <TableSettings
            columns={columns}
            visibleColumnsKeys={visibleColumns}
            setVisibleColumnsKeys={setVisibleColumns}
          />
        </div>
      </div>
      <Table
        className={styles.table}
        rowKey={record => JSON.stringify(record)}
        bordered
        dataSource={withdrawals || []}
        columns={filteredColumns}
        scroll={{ x: 1200 }}
        locale={{ emptyText: <Empty description='Нет данных' /> }}
        loading={loading}
      />
        <div className={styles.mobileCards}>
            <MobilePavilionSearch
                options={pavilions?.items || []}
                searchHandler={searchPavilionHandler}
                selectHandler={handleSelectPavilion}
                resetHandler={resetPavilionHandler}
            />
            <div className={styles.activeToggler}>
                <Switch
                    size='small'
                    checked={searchParams.statuses === Statuses.PENDING}
                    onChange={(value) => {
                        const { statuses, ...other } = searchParams;
                        setSearchParams(value ? {...searchParams, statuses: Statuses.PENDING, page: 1}: {...other, page: 1})}
                    }
                />
                <span style={{display: "block", marginLeft: 8}}>Только активные</span>
            </div>
            {(withdrawals || []).map((el) => {
                // eslint-disable-next-line no-nested-ternary
                const resultStyle = el.status === Statuses.PENDING ? statusColors.blue : (el.status === Statuses.APPROVED ? statusColors.black : statusColors.red)
                const today = new Date();
                const date = new Date(el.created_at)
                // @ts-ignore
                const diff =  (today - date) / (1000 * 60 * 60);
                return (
                    <div className={styles.mobileCardWrapper}>
                        <div className={styles.mobileCardMainInfo}>
                            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 12}}>
                                <div>
                                    <span style={{display: 'block', fontSize: 20, fontWeight: 700}}>
                                        {new Intl.NumberFormat().format(el.amount / 100)} ₽
                                    </span>
                                    <span style={{display: 'block', fontSize: 14, fontWeight: 600}}>
                                        {el.pavilions.map((el: { name: string }) => el.name).join(', ')}
                                    </span>
                                </div>
                                <Tag style={{...resultStyle, borderRadius: 24, padding: '4px 12px', height: 'fit-content'}}>{el.status_name}</Tag>
                            </div>
                            <div>
                                {el.status === Statuses.PENDING ?
                                <div>
                                    <Button onClick={() => onWithdrawal(el, Statuses.APPROVED)} type='primary' style={{marginRight: 8, borderRadius: 8}}>
                                        Погасить
                                    </Button>
                                    <Button onClick={() => onWithdrawal(el, Statuses.REJECTED)} style={{borderRadius: 8}}>
                                        Отклонить
                                    </Button>
                                </div> :
                                <span>
                                    {`${el.status === Statuses.APPROVED ? 'Погасил' : 'Отменил'} ${el.updated_by.name}`}
                                </span>
                                }
                            </div>
                        </div>
                        <div className={styles.mobileCardSubInfo}>
                            <Link to={`/profile/supplier/${el.supplier.id}`} className={styles.link}>
                                {el.supplier.name}
                            </Link>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 8, alignItems: 'center'}}>
                                <CopyText className={styles.nowrap} text={el.supplier.phone}>
                                    {getFormattedPhone(el.supplier.phone)}
                                </CopyText>
                                <span style={(el.status === Statuses.PENDING && diff > 24) ? {color: 'red'} : {}}>
                                  {Utils.getFormatDate(el.created_at, 'DD.MM.YYYY HH:mm')}
                                </span>
                            </div>
                        </div>
                    </div>
                )
            })
            }
        </div>
      {withdrawal && <WithdrawalModal item={withdrawal} onClose={() => setWithdrawal(undefined)} newStatus={newStatus}/>}
    </div>
  );
};
