export enum CommissionSettingsKeys {
  BankCommission = 'bank_commission_percent',
  BankMinAmountCommission = 'bank_commission_min_amount',
  Ffd_version = 'ffd_version',
  CommissionClientAppCard = 'commission_client_app_card',
  CommissionClientAppCardIntermediarySkipped = 'commission_client_app_card_intermediary_skipped',
  CommissionClientAppCash = 'commission_client_app_cash',
  CommissionClientAppCashIntermediarySkipped = 'commission_client_app_cash_intermediary_skipped',
  CommissionClientLkCard = 'commission_client_lk_card',
  CommissionClientLkCardIntermediarySkipped = 'commission_client_lk_card_intermediary_skipped',
  CommissionClientLkCash = 'commission_client_lk_cash',
  CommissionClientLkCashIntermediarySkipped = 'commission_client_lk_cash_intermediary_skipped',
  CommissionClientAppCardFixed = 'commission_client_app_card_fixed',
  CommissionClientAppCardIntermediarySkippedFixed = 'commission_client_app_card_intermediary_skipped_fixed',
  CommissionClientAppCashFixed = 'commission_client_app_cash_fixed',
  CommissionClientAppCashIntermediarySkippedFixed = 'commission_client_app_cash_intermediary_skipped_fixed',
  CommissionClientLkCardFixed = 'commission_client_lk_card_fixed',
  CommissionClientLkCardIntermediarySkippedFixed = 'commission_client_lk_card_intermediary_skipped_fixed',
  CommissionClientLkCashFixed = 'commission_client_lk_cash_fixed',
  CommissionClientLkCashIntermediarySkippedFixed = 'commission_client_lk_cash_intermediary_skipped_fixed',
  CommissionIntermediaryAppCard = 'commission_intermediary_app_card',
  CommissionIntermediaryAppCardIntermediarySkipped = 'commission_intermediary_app_card_intermediary_skipped',
  CommissionIntermediaryAppCash = 'commission_intermediary_app_cash',
  CommissionIntermediaryAppCashIntermediarySkipped = 'commission_intermediary_app_cash_intermediary_skipped',
  CommissionIntermediaryLkCard = 'commission_intermediary_lk_card',
  CommissionIntermediaryLkCardIntermediarySkipped = 'commission_intermediary_lk_card_intermediary_skipped',
  CommissionIntermediaryLkCash = 'commission_intermediary_lk_cash',
  CommissionIntermediaryLkCashIntermediarySkipped = 'commission_intermediary_lk_cash_intermediary_skipped',
  CommissionIntermediarySellerCard = 'commission_intermediary_seller_card',
  CommissionIntermediarySellerCardIntermediarySkipped = 'commission_intermediary_seller_card_intermediary_skipped',
  CommissionIntermediarySellerCash = 'commission_intermediary_seller_cash',
  CommissionIntermediarySellerCashIntermediarySkipped = 'commission_intermediary_seller_cash_intermediary_skipped',
}

export interface CommissionTypes {
  bank_commission_percent: string;
  bank_commission_min_amount: string;
  ffd_version: string;
  commission_client_app_card: string;
  commission_client_app_card_intermediary_skipped: string;
  commission_client_app_cash: string;
  commission_client_app_cash_intermediary_skipped: string;
  commission_client_lk_card: string;
  commission_client_lk_card_intermediary_skipped: string;
  commission_client_lk_cash: string;
  commission_client_lk_cash_intermediary_skipped: string;
  commission_intermediary_app_card: string;
  commission_intermediary_app_card_intermediary_skipped: string;
  commission_intermediary_app_cash: string;
  commission_intermediary_app_cash_intermediary_skipped: string;
  commission_intermediary_lk_card: string;
  commission_intermediary_lk_card_intermediary_skipped: string;
  commission_intermediary_lk_cash: string;
  commission_intermediary_lk_cash_intermediary_skipped: string;
  commission_intermediary_seller_card: string;
  commission_intermediary_seller_card_intermediary_skipped: string;
  commission_intermediary_seller_cash: string;
  commission_intermediary_seller_cash_intermediary_skipped: string;
}

export interface Commission {
  settings: {
    id: number;
    key: CommissionSettingsKeys;
    value: string;
  }[];
}

export interface resultCommission {
  [key: string]: string | number;
}

export type TFormField = { label: string; name: string; fixed?: boolean; isSumma?: boolean };
