import { TPagination, TSimpleNameObject } from 'src/utils';

import api from '../api';

export type TSellerWithdrawalRequests = {
  "withdrawals": TSellerWithdrawal[],
  "sum": {
    "status": Statuses,
    "status_name": string,
    "sum": number
  }[],
  "pagination": {
    "per_page": number,
    "current_page": number,
    "total": number,
    "last_page": number
  }
}

export enum Statuses {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected'
}

export type TSellerWithdrawalSum = {
  status: Statuses
  status_name: string
  sum: number
}

export type TSellerWithdrawal = {
  "id": number,
  "pavilions": {
    "id": number,
    "name": string
  }[],
  "status": string,
  "status_name": string,
  "amount": number,
  "created_at": string,
  "supplier": {
    "id": number,
    "name": string,
    "description": string,
    "avatar": string,
    "phone": string,
    "legal_entity_id": number,
    "top_status": boolean,
    "has_lk": boolean
  },
  "updated_by": {
    "id": number,
    "name": string,
    "email": string,
    "phone": string,
    "role": string,
    "created_at": string,
    "trashed": boolean
  }
}

export type TGetSellerHistoryRequest = {
  userId?: number;
  date_from?: string;
  date_to?: string;
  task_ids?: string[];
  operation_types?: string[];
  amount?: number;
  supplier_id?: number;
  pavilion_id?: number;
  created_by?: number;
  pagination: {
    per_page: number;
    page: number;
  };
};

export type TGetSellerHistoryResponse = {
  records: TSellerHistoryEntity[];
  totalIn: number;
  totalOut: number;
  pagination: TPagination;
};

export type TSellerHistoryEntity = {
  id: number;
  amount: number;
  operation_type_id: number;
  operation_type_name: string;
  comment: string;
  recipient_id: number;
  recipient_name: string;
  supplier_id?: string;
  supplier_name?: string;
  donor_id: number;
  donor_name: string;
  pavilion_name?: string;
  external_id: string;
  created_at: string;
  created_by?: string;
};

export type TGetSellerOperationTypesResponse = {
  types: TSimpleNameObject[];
};

export const getSellerWithdrawals = (externalString: string): Promise<TSellerWithdrawalRequests> =>
    api.get(`/seller/withdrawal-requests${externalString}`).then(response => response.data);

export const exportSellerBalances = (): Promise<{url: string;}> =>
  api.get('/seller-balances/export').then(response => response.data);

export const exportWithdrawalRequests = (externalString: string): Promise<{url: string;}> =>
    api.get(`/seller/withdrawal-requests-export${externalString}`).then(response => response.data);

export const getSellerHistory = (data: TGetSellerHistoryRequest): Promise<TGetSellerHistoryResponse> =>
  api.post('/seller-history', data).then(response => response.data);

export const updateSellerBalance = (id: number, data: { status: Statuses }) =>
  api.put(`/seller/withdrawal-requests/${id}`, data);

export const getSellerOperationTypes = (): Promise<TGetSellerOperationTypesResponse> =>
  api.get('/seller-operation-types').then(response => response.data);

export const postSellerBalanceCorrection = (data: {
  userId: number,
  amount: number,
  reason: string,
  operation_type_id: number
}): Promise<{ balance: number }> =>
    api.post('/seller-balance-correction', data).then(response => response.data);

export const exportSellerHistory = (data: TGetSellerHistoryRequest): Promise<{url: string;}> =>
  api.post('/seller-history/export', data).then(response => response.data);
