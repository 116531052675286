import React, {useState} from 'react';
import {MainModal, Title} from 'src/shared/ui';
import {Button, Form} from 'antd';
import {Statuses, TSellerWithdrawal} from 'src/shared/api/queries/sellerBillingApi';

import styles from './WithdrawalModal.module.less';
import {usePaymentsWithdrawal} from '../../hooks/usePaymentsWithdrawal';

interface IProps {
  readonly onClose: () => void;
  readonly item: TSellerWithdrawal;
  readonly newStatus: Statuses;
}

export const WithdrawalModal: React.FC<IProps> = ({ onClose, item, newStatus }) => {
  const { onWithdrawal } = usePaymentsWithdrawal();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    await onWithdrawal?.({
      id: item.id,
      data: {
        status: newStatus
      },
      callback: onClose,
    });
    setLoading(false);
  };
  const amount = (item.amount/100).toLocaleString();
  const pavilion = item.pavilions.map((el: { name: string }) => el.name).join(', ');
  return (
    <MainModal isOpen closeHandler={onClose}>
      <div className={styles.container}>
        <Title>{newStatus === Statuses.APPROVED ? 'Погасить заявку?' : 'Отменить заявку?'}</Title>
        <Form name='basic' layout='vertical' onFinish={handleSubmit} autoComplete='off'>
          <span className={styles.content}>
            {
                newStatus === Statuses.APPROVED ?
                    `Вы подтверждаете, что выплатили наличными ${amount} ₽ поставщику на павильоне ${pavilion}` :
                    `Вы отказываете в выплате ${amount} ₽ наличными поставщику на павильоне ${pavilion}`
            }
          </span>
          <div className={styles.buttons}>
            <Form.Item style={{marginBottom: 0}}>
              <Button htmlType='submit' className={styles.button} type='primary' disabled={loading} loading={loading}>
                Подтвердить
              </Button>
            </Form.Item>
            <Button onClick={onClose} className={styles.button} disabled={loading}>
              Отменить
            </Button>
          </div>
        </Form>
      </div>
    </MainModal>
  );
};
