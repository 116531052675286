import {createEffect, createEvent, createStore, sample} from 'effector';
import { Storage } from 'src/shared/lib';
import { Paginated, Pavilion } from 'src/shared/types';
import { suppliersApi, pavilionsApi } from 'src/shared/api';
import { defaultInitState, PER_PAGE } from 'src/shared/config';

export const $pavilions = createStore<Paginated<Pavilion> | null>(null);
export const $requestData = createStore<suppliersApi.SuppRequestData>(defaultInitState);
export const $pageSize = createStore<number>(PER_PAGE);
Storage.persist.entity($pageSize, { slice: 'suppliers', key: 'pageSize' });

export const submitChangeRequestData = createEvent<suppliersApi.SuppRequestData>();
export const changeVisibleColumns = createEvent<Record<string, boolean>>();
export const handleModal = createEvent<boolean>();
export const changePageSize = createEvent<number>();
export const getPavilionsFx = createEffect(pavilionsApi.getPavilions);
$pavilions.on(getPavilionsFx.doneData, (_, data) => data);

export const initPagePavilions = createEvent<pavilionsApi.getPavilionsReqData>();

sample({
    clock: initPagePavilions,
    target: getPavilionsFx,
});
