import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { sellerBillingApi } from 'src/shared/api';
import { TGetSellerHistoryRequest, TSellerHistoryEntity } from 'src/shared/api/queries/sellerBillingApi';
import { AxiosError } from 'axios';
import { notification } from 'antd';

import { initState } from '../PaymentsSuppliersPage.const';

type TReturnValues = {
  readonly data: TSellerHistoryEntity[];
  readonly loading: boolean;
  readonly total: number;
  readonly searchParams: TGetSellerHistoryRequest;
  readonly setSearchParams: React.Dispatch<React.SetStateAction<TGetSellerHistoryRequest>>;
  readonly balances: TBalances;
  readonly onExport: () => Promise<void>;
};

type TBalances = {
  totalIn: string;
  totalOut: string;
};

const PaymentsSuppliersContext = createContext<Partial<TReturnValues>>({});

export const PaymentsSuppliersProvider = ({ children }: { children: ReactNode }) => {
  const [data, setData] = useState<TSellerHistoryEntity[]>([]);
  const [balances, setBalances] = useState<TBalances>();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [searchParams, setSearchParams] = useState<TGetSellerHistoryRequest>({
    ...initState,
  });

  const onExport = async () => {
    try {
      const response = await sellerBillingApi.exportSellerHistory(searchParams);
      if (window && response?.url) {
        window.location.replace(response?.url)
      }
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      notification.error({ message: error.response?.data.message || 'Ошибка экспорта истории поставщика' });
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await sellerBillingApi.getSellerHistory(searchParams);

      setData(response?.records);
      setTotal(response?.pagination?.total);
      setBalances({
        totalIn: new Intl.NumberFormat().format(response.totalIn / 100),
        totalOut: new Intl.NumberFormat().format(response.totalOut / 100),
      });
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      notification.error({ message: error.response?.data.message || 'Ошибка загрузки истории поставщика' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchParams]);

  const values = useMemo(
    () => ({
      data,
      loading,
      total,
      searchParams,
      setSearchParams,
      balances,
      onExport,
    }),
    [data, loading, total, balances, searchParams],
  );

  return <PaymentsSuppliersContext.Provider value={values}>{children}</PaymentsSuppliersContext.Provider>;
};

export const usePaymentsSuppliers = (): Partial<TReturnValues> => ({ ...useContext(PaymentsSuppliersContext) });
