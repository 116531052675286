import { CommissionSettingsKeys } from './types';

export const FORM_FIELDS = {
  client: {
    mobileAppAll: [
      {
        label: 'Комиссия при оплате картой',
        name: CommissionSettingsKeys.CommissionClientAppCard,
        fixed: true,
      },
      {
        label: 'Комиссия при оплате картой с пропуском посредника',
        name: CommissionSettingsKeys.CommissionClientAppCardIntermediarySkipped,
        fixed: true,
      },
      {
        label: 'Комиссия при оплате в кассу',
        name: CommissionSettingsKeys.CommissionClientAppCash,
        fixed: true,
      },
      {
        label: 'Комиссия при оплате в кассу с пропуском посредника',
        name: CommissionSettingsKeys.CommissionClientAppCashIntermediarySkipped,
        fixed: true,
      },
    ],
    webLkAll: [
      {
        label: 'Комиссия при оплате картой',
        name: CommissionSettingsKeys.CommissionClientLkCard,
        fixed: true,
      },
      {
        label: 'Комиссия при оплате картой с пропуском посредника',
        name: CommissionSettingsKeys.CommissionClientLkCardIntermediarySkipped,
        fixed: true,
      },
      {
        label: 'Комиссия при оплате в кассу',
        name: CommissionSettingsKeys.CommissionClientLkCash,
        fixed: true,
      },
      {
        label: 'Комиссия при оплате в кассу с пропуском посредника',
        name: CommissionSettingsKeys.CommissionClientLkCashIntermediarySkipped,
        fixed: true,
      },
    ],
  },

  posrednik: {
    mobileAppAll: [
      {
        name: CommissionSettingsKeys.CommissionIntermediaryAppCard,
        label: 'Вознаграждение за задание, оплаченное картой',
      },
      {
        name: CommissionSettingsKeys.CommissionIntermediaryAppCardIntermediarySkipped,
        label: 'Вознаграждение за задание, оплаченное картой с пропуском посредника',
      },
      {
        name: CommissionSettingsKeys.CommissionIntermediaryAppCash,
        label: 'Вознаграждение за задание, оплаченное в кассу',
      },
      {
        name: CommissionSettingsKeys.CommissionIntermediaryAppCashIntermediarySkipped,
        label: 'Вознаграждение за задание, оплаченное в кассу с пропуском посредника',
      },
    ],
    webLkAll: [
      {
        name: CommissionSettingsKeys.CommissionIntermediaryLkCard,
        label: 'Вознаграждение за задание, оплаченное картой',
      },
      {
        name: CommissionSettingsKeys.CommissionIntermediaryLkCardIntermediarySkipped,
        label: 'Вознаграждение за задание, оплаченное картой с пропуском посредника',
      },
      {
        name: CommissionSettingsKeys.CommissionIntermediaryLkCash,
        label: 'Вознаграждение за задание, оплаченное в кассу',
      },
      {
        name: CommissionSettingsKeys.CommissionIntermediaryLkCashIntermediarySkipped,
        label: 'Вознаграждение за задание, оплаченное в кассу с пропуском посредника',
      },
    ],
    sellerAll: [
      {
        name: CommissionSettingsKeys.CommissionIntermediarySellerCard,
        label: 'Вознаграждение за задание, оплаченное картой',
      },
      {
        name: CommissionSettingsKeys.CommissionIntermediarySellerCardIntermediarySkipped,
        label: 'Вознаграждение за задание, оплаченное картой с пропуском посредника',
      },
      {
        name: CommissionSettingsKeys.CommissionIntermediarySellerCash,
        label: 'Вознаграждение за задание, оплаченное в кассу',
      },
      {
        name: CommissionSettingsKeys.CommissionIntermediarySellerCashIntermediarySkipped,
        label: 'Вознаграждение за задание, оплаченное в кассу с пропуском посредника',
      },
    ],
  },

  bank: [
    {
      name: CommissionSettingsKeys.BankMinAmountCommission,
      label: 'Минимальная сумма комиссии банка, ₽',
      isSumma: true,
    },
    {
      name: CommissionSettingsKeys.BankCommission,
      label: 'Комиссия банка',
    },
  ],
};

export const FIELDS_MESSAGES = [
  {
    name: CommissionSettingsKeys.CommissionClientAppCard,
    message: 'Пожалуйста, введите комиссию при оплате картой',
  },
  {
    name: CommissionSettingsKeys.CommissionClientAppCardIntermediarySkipped,
    message: 'Пожалуйста, введите комиссию при оплате картой с пропуском посредника',
  },
  {
    name: CommissionSettingsKeys.CommissionClientAppCash,
    message: 'Пожалуйста, введите комиссию при оплате в кассу',
  },
  {
    name: CommissionSettingsKeys.CommissionClientAppCashIntermediarySkipped,
    message: 'Пожалуйста, введите комиссию при оплате в кассу с пропуском посредника',
  },
  {
    name: CommissionSettingsKeys.CommissionClientLkCard,
    message: 'Пожалуйста, введите комиссию при оплате картой',
  },
  {
    name: CommissionSettingsKeys.CommissionClientLkCardIntermediarySkipped,
    message: 'Пожалуйста, введите комиссию при оплате картой с пропуском посредника',
  },
  {
    name: CommissionSettingsKeys.CommissionClientLkCash,
    message: 'Пожалуйста, введите комиссию при оплате в кассу',
  },
  {
    name: CommissionSettingsKeys.CommissionClientLkCashIntermediarySkipped,
    message: 'Пожалуйста, введите комиссию при оплате в кассу с пропуском посредника',
  },

  {
    name: CommissionSettingsKeys.CommissionClientAppCardFixed,
    message: 'Пожалуйста, введите комиссию при оплате картой',
  },
  {
    name: CommissionSettingsKeys.CommissionClientAppCardIntermediarySkippedFixed,
    message: 'Пожалуйста, введите комиссию при оплате картой с пропуском посредника',
  },
  {
    name: CommissionSettingsKeys.CommissionClientAppCashFixed,
    message: 'Пожалуйста, введите комиссию при оплате в кассу',
  },
  {
    name: CommissionSettingsKeys.CommissionClientAppCashIntermediarySkippedFixed,
    message: 'Пожалуйста, введите комиссию при оплате в кассу с пропуском посредника',
  },
  {
    name: CommissionSettingsKeys.CommissionClientLkCardFixed,
    message: 'Пожалуйста, введите комиссию при оплате картой',
  },
  {
    name: CommissionSettingsKeys.CommissionClientLkCardIntermediarySkippedFixed,
    message: 'Пожалуйста, введите комиссию при оплате картой с пропуском посредника',
  },
  {
    name: CommissionSettingsKeys.CommissionClientLkCashFixed,
    message: 'Пожалуйста, введите комиссию при оплате в кассу',
  },
  {
    name: CommissionSettingsKeys.CommissionClientLkCashIntermediarySkippedFixed,
    message: 'Пожалуйста, введите комиссию при оплате в кассу с пропуском посредника',
  },

  {
    name: CommissionSettingsKeys.CommissionIntermediaryAppCard,
    message: 'Пожалуйста, введите вознаграждение за задание, оплаченное картой',
  },
  {
    name: CommissionSettingsKeys.CommissionIntermediaryAppCardIntermediarySkipped,
    message: 'Пожалуйста, введите вознаграждение за задание, оплаченное картой с пропуском посредника',
  },
  {
    name: CommissionSettingsKeys.CommissionIntermediaryAppCash,
    message: 'Пожалуйста, введите вознаграждение за задание, оплаченное в кассу',
  },
  {
    name: CommissionSettingsKeys.CommissionIntermediaryAppCashIntermediarySkipped,
    message: 'Пожалуйста, введите вознаграждение за задание, оплаченное в кассу с пропуском посредника',
  },
  {
    name: CommissionSettingsKeys.CommissionIntermediaryLkCard,
    message: 'Пожалуйста, введите вознаграждение за задание, оплаченное картой',
  },
  {
    name: CommissionSettingsKeys.CommissionIntermediaryLkCardIntermediarySkipped,
    message: 'Пожалуйста, введите вознаграждение за задание, оплаченное картой с пропуском посредника',
  },
  {
    name: CommissionSettingsKeys.CommissionIntermediaryLkCash,
    message: 'Пожалуйста, введите вознаграждение за задание, оплаченное в кассу',
  },
  {
    name: CommissionSettingsKeys.CommissionIntermediaryLkCashIntermediarySkipped,
    message: 'Пожалуйста, введите вознаграждение за задание, оплаченное в кассу с пропуском посредника',
  },
  {
    name: CommissionSettingsKeys.CommissionIntermediarySellerCard,
    message: 'Пожалуйста, введите вознаграждение за задание, оплаченное картой',
  },
  {
    name: CommissionSettingsKeys.CommissionIntermediarySellerCardIntermediarySkipped,
    message: 'Пожалуйста, введите вознаграждение за задание, оплаченное картой с пропуском посредника',
  },
  {
    name: CommissionSettingsKeys.CommissionIntermediarySellerCash,
    message: 'Пожалуйста, введите вознаграждение за задание, оплаченное в кассу',
  },
  {
    name: CommissionSettingsKeys.CommissionIntermediarySellerCashIntermediarySkipped,
    message: 'Пожалуйста, введите вознаграждение за задание, оплаченное в кассу с пропуском посредника',
  },
  {
    name: CommissionSettingsKeys.BankMinAmountCommission,
    message: 'Пожалуйста, введите минимальную сумму комиссии банка',
  },
  {
    name: CommissionSettingsKeys.BankCommission,
    message: 'Пожалуйста, введите комиссию банка',
  },
];

export const SUMM_AS_KOPEK_FIELDS = [CommissionSettingsKeys.BankMinAmountCommission];
