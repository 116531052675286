import {ColumnsType} from 'antd/lib/table';
import {Statuses, TSellerWithdrawal} from 'src/shared/api/queries/sellerBillingApi';
import classNames from 'classnames';
import {getFormattedPhone, Utils} from 'src/shared/lib';
import {PropsWithChildren} from 'react';
import {Button, Tag} from 'antd';
import {Link} from 'react-router-dom';

import styles from './PaymentsWithdrawalPage.module.less';
import {statusColors} from "../../../shared/config";

export const CopyText = ({ children, text, className }: PropsWithChildren<{ text: string; className?: string }>) => (
  // eslint-disable-next-line
  <span className={classNames([className, styles.copy])} onClick={() => navigator.clipboard.writeText(text)}>
    {children}
  </span>
);

export const getColumns = ({ onWithdrawal }: { readonly onWithdrawal: (value: TSellerWithdrawal, status: Statuses) => void }) => {
  const columns: ColumnsType<TSellerWithdrawal> = [
    {
      title: 'Статус',
      key: 'status',
      render: record => {
        // eslint-disable-next-line no-nested-ternary
        const resultStyle = record.status === Statuses.PENDING ? statusColors.blue : (record.status === Statuses.APPROVED ? statusColors.black : statusColors.red)
        return <Tag style={{...resultStyle, borderRadius: 24, padding: '4px 12px'}}>{record.status_name}</Tag>
      },
      sorter: (a, b) => {return a.status.localeCompare(b.status)}
    },
    {
      title: 'Сумма',
      dataIndex: 'amount',
      key: 'amount',
      render: record => (
        <CopyText className={styles.nowrap} text={String(record / 100)}>
          {new Intl.NumberFormat().format(record / 100)} ₽
        </CopyText>
      ),
      sorter: (a, b) => {return a.amount - b.amount},
    },
    {
      title: 'Павильон',
      dataIndex: 'pavilions',
      key: 'pavilions',
      render: record =>  record.map((el: { name: string }) => el.name).join(', '),
      sorter: (a, b) => {return a.pavilions.map((el: { name: string }) => el.name).join(', ').localeCompare(b.pavilions.map((el: { name: string }) => el.name).join(', '))}
    },
    {
      title: 'Телефон',
      dataIndex: 'supplier',
      key: 'supplier.phone',
      render: record =>
        record ? (
          <CopyText className={styles.nowrap} text={record.phone}>
            {getFormattedPhone(record.phone)}
          </CopyText>
        ) : (
          '-'
        ),
      sorter: (a, b) => {return a.supplier.phone.localeCompare(b.supplier.phone)}
    },
    {
      title: 'Дата',
      key: 'created_at',
      render: record => {
        const today = new Date();
        const date = new Date(record.created_at)
        // @ts-ignore
        const diff =  (today - date) / (1000 * 60 * 60);
        return (
            <span style={(record.status === Statuses.PENDING && diff > 24) ? {color: 'red'} : {}}>
              {Utils.getFormatDate(record.created_at, 'DD.MM.YYYY HH:mm')}
            </span>
        )
      },
      sorter: (a, b) => {return a.created_at.localeCompare(b.created_at)}
    },
    {
      title: 'Поставщик',
      dataIndex: 'supplier',
      key: 'supplier',
      render: record => (
        <Link to={`/profile/supplier/${record.id}`} className={styles.link}>
          {record.name}
        </Link>
      ),
      sorter: (a, b) => {return a.supplier.name.localeCompare(b.supplier.name)}
    },
    {
      title: 'Действие',
      key: 'actions',
      render: record => (
          record.status === Statuses.PENDING ?
          <div>
            <Button onClick={() => onWithdrawal(record, Statuses.APPROVED)} type='primary' style={{marginRight: 8}}>
              Погасить
            </Button>
            <Button onClick={() => onWithdrawal(record, Statuses.REJECTED)}>
              Отклонить
            </Button>
          </div> :
          <span>
            {`${record.status === Statuses.APPROVED ? 'Погасил' : 'Отменил'} ${record.updated_by.name}`}
          </span>
      ),
    },
  ];

  const initColumnSettings = columns.reduce((prev, curr) => {
    if (curr.key) {
      prev[curr.key] = true;
    }
    return prev;
  }, {} as Record<string, boolean>);

  return {
    columns,
    initColumnSettings,
  };
};
