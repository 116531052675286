import {Button, Form, Input, Select} from "antd";
import { sellerBillingApi } from 'src/shared/api';

import {MainModal, Title} from "../../shared/ui";
import styles from './ReplenishModal.module.less';
import {setSupplierBalance} from "../../pages/profiles/suppliersProfile/model";

const WriteOffOperations = [
    {label: 'Выплата наличными', value: 2},
    {label: 'Удержание', value: 11},
    {label: 'Возврат товара', value: 10},
]


interface IWriteOffModalProps {
    isOpen: boolean;
    handleClose: () => void;
    userId: string;
}

export const WriteOffModal = ({isOpen, handleClose, userId}: IWriteOffModalProps) => {
    const [form] = Form.useForm();

    const handleSubmit = (data: {amount: string, reason: string, operation_id: number}) => {
         sellerBillingApi.postSellerBalanceCorrection({
            reason: data.reason,
            amount: +data.amount * 100,
            userId: +userId,
            operation_type_id: data.operation_id
        }).then(({balance}) => {
            setSupplierBalance(balance);
            handleClose()
        })
    }

    return (
        <MainModal isOpen={isOpen} closeHandler={handleClose}>
            <div className={styles.replenishForm}>
                <Title>Списать с баланса</Title>
                <Form form={form} name="basic" layout="vertical" onFinish={handleSubmit} autoComplete="off">
                    <Form.Item
                        label='Сумма списания, в ₽'
                        name='amount'
                        rules={[
                            {
                                type: 'number',
                                max: 999999999,
                                min: 0,
                                message: 'Число больше нуля',
                                transform(value) {
                                    return Number(value);
                                },
                            },
                            { required: true, message: 'Пожалуйста, введите сумму списания!' },
                        ]}
                    >
                        <Input placeholder='0' />
                    </Form.Item>
                    <Form.Item
                        rules={[{ required: true, message: 'Пожалуйста, введите тип транзакции!' }]}
                        label="Тип транзакции"
                        name="operation_id">
                        <Select style={{ width: '100%' }}>
                            {WriteOffOperations.map(({value, label}) => (
                                <Select.Option key={value} value={value}>
                                    {label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label='Описание'
                        name='reason'
                        rules={[
                            { required: true, message: 'Пожалуйста, введите описание!' },
                        ]}
                    >
                        <Input placeholder='Описание увидит поставщик' />
                    </Form.Item>

                    <div className={styles.buttons}>
                        <Form.Item>
                            <Button htmlType="submit" className={styles.button} type="primary">
                                Готово
                            </Button>
                        </Form.Item>
                        <Button onClick={handleClose} className={styles.button}>
                            Отмена
                        </Button>
                    </div>
                </Form>
            </div>
        </MainModal>
    )
}